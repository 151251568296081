import dynamic from 'next/dynamic';
import { FC } from 'react';

// Interfaces
import { SectionTypes, CfSection } from 'interfaces/Contentful';

// Dynamic Components
const SectionLogos = dynamic(() => import('./Logotypes'));
const SectionProductsList = dynamic(() => import('./ProductsList'));
const SectionQuote = dynamic(() => import('./Quote'));
const PromotedProduct = dynamic(() => import('components/product/PromotedProduct'));

interface Props {
  sections?: CfSection[];
}

// Components list is a dictionary where the key is one of the SectionTypes
// and the value is a component

const Sections: FC<Props> = ({ sections }) => {
  if (sections) {
    const elements = sections.map((section) => {
      const key = section.id;
      switch (section.type) {
        case SectionTypes.ProductsList:
          return <SectionProductsList key={key} content={section} />;
        case SectionTypes.Logotypes:
          return <SectionLogos key={key} content={section} />;
        case SectionTypes.Quote:
          return <SectionQuote key={key} content={section} />;
        case SectionTypes.SingleProduct:
          return (
            <PromotedProduct
              key={key}
              product={section.product}
              containerWidth='max-width-md'
              useBackgroundColor={false}
            />
          );
      }
    });
    return <div>{elements}</div>;
  }
  return null;
};

export default Sections;
