import { GetServerSideProps, NextPage } from 'next';
import Error from 'next/error';
import Link from 'next/link';
import cn from 'classnames';

// Components
import { Layout, Hero } from 'components/common';
import Sections from '../components/sections';

// Interfaces
import { CfHome } from 'interfaces/Contentful/CfHome';
import { PageError } from 'interfaces/error';

// Services
import { getContentfulSettings, getContentfulStartPage } from 'services/contentful';
import { getUrlForContentType } from 'utils/routes';
import { convertReqToBearerToken } from 'utils/authUtil';

interface Props {
  content?: CfHome;
  error?: PageError;
}

const Home: NextPage<Props> = ({ content, error }) => {
  if (error) {
    return <Error statusCode={error.statusCode} title={error.message} />;
  }

  if (!content || content === null) {
    return <Error statusCode={404} title={'No page was found'} />;
  }

  return (
    <div>
      <Hero image={content.hero.featuredMedia}>
        <h1>{content.hero.title}</h1>
        {content.hero.body && <p>{content.hero.body}</p>}
        {content.hero.callToActions && (
          <div className='flex gap-sm'>
            {content.hero.callToActions.map((cta, index) => {
              const slug = getUrlForContentType(cta.slug, cta.contentTypeId);
              const classes = cn('btn btn--md', {
                'btn--white': index === 0,
              });
              return (
                <Link href={slug} key={cta.id} passHref>
                  <a title={cta.title} className={classes}>
                    {cta.title}
                  </a>
                </Link>
              );
            })}
          </div>
        )}
      </Hero>
      <Sections sections={content.sections} />
    </div>
  );
};

export const getServerSideProps: GetServerSideProps<Props> = async ({ locale, req }) => {
  const cfResponse = await getContentfulStartPage({
    params: { locale },
    headers: {
      Authorization: 'Bearer ' + convertReqToBearerToken(req),
    },
  });
  const settings = await getContentfulSettings(locale);

  if (!cfResponse.data) {
    return {
      props: {
        settings: settings.data,
        error: {
          statusCode: 404,
          message: `No Contentful data could be found`,
        },
      },
    };
  }

  return {
    props: {
      settings: settings.data,
      content: cfResponse.data,
    },
  };
};

// @ts-ignore custom implementation that NextPage doesn't cover
Home.Layout = Layout;

export default Home;
